var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("1:1 문의")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSearch = !_vm.showSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-expand-transition', [_c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSearch,
      expression: "showSearch"
    }],
    staticClass: "elevation-1 mx-auto"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("검색필터")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-date-field', {
    attrs: {
      "label": "작성일자",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.createdAt[0],
      callback: function ($$v) {
        _vm.$set(_vm.filter.createdAt, 0, $$v);
      },
      expression: "filter.createdAt[0]"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-date-field', {
    attrs: {
      "label": "작성일자",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.createdAt[1],
      callback: function ($$v) {
        _vm.$set(_vm.filter.createdAt, 1, $$v);
      },
      expression: "filter.createdAt[1]"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.searchKeys,
      "label": "검색조건",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "검색어",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": !_vm.filter.searchKey
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        (_vm.page = 1) && _vm.search();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v("검색")], 1)], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.questionsHeaders,
      "items": _vm.questions,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.product",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.product ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.product.thumb ? _c('v-img', {
          attrs: {
            "src": item.product.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(item.product.name))])])], 1) : _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v("삭제된 상품")])])], 1)];
      }
    }, {
      key: "item.user",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.user && item.user.companyName ? _c('div', [_vm._v(_vm._s(item.user.companyName.ko))]) : _vm._e()];
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: "item.reply",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("미답변")])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }])
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.questionDialog,
      callback: function ($$v) {
        _vm.questionDialog = $$v;
      },
      expression: "questionDialog"
    }
  }, [_vm.questionDialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품문의")])]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "문의 제목",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의날짜",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.createdAt.toDate()
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 내용",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "답변",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.reply,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "reply", $$v);
      },
      expression: "editItem.reply"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }