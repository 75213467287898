<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">입주관심 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자(시작)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자(종료)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder dense hide-details @change="()=> filter.searchValue = ''"/>
                            </v-col>
                            <v-col cols="6" md="3" v-if="[null, 'name', 'phone', 'birth'].includes(filter.searchKey)">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder :disabled="!filter.searchKey" dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3" v-if="['interest_type'].includes(filter.searchKey)">
                                <v-select v-model="filter.searchValue" :items="interest_items" label="공급유형" persistent-placeholder :disabled="!filter.searchKey" dense hide-details/>
                            </v-col>
                            <v-col cols="6" md="3" v-if="['gender'].includes(filter.searchKey)">
                                <v-select v-model="filter.searchValue" :items="gender_items" label="신청자격" persistent-placeholder :disabled="!filter.searchKey" dense hide-details/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" rounded text @click="initSearch"><v-icon small class="mt-1 mr-2">mdi-replay</v-icon>초기화</v-btn>
                        <v-btn color="primary" rounded text @click="onClickSearch"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀다운로드 </v-btn>
                </v-col>
            </v-row>
            
            <v-data-table :items="applications" :headers="headers" disable-sort disable-pagination hide-default-footer class="elevation-1 ">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+applications.indexOf(item) + 1) }}
                </template>
                <template #[`item.interest_type`]="{ item }">
                    {{ INTEREST_TYPE[item.interest_type]?.text }}
                </template>
                <template #[`item.gender`]="{ item }">
                    {{ GENDER_TYPE[item.gender]?.text }}
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정" class="ml-2">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>
        </v-responsive>

        <v-dialog v-model="shows" max-width="720px">
            <v-card>
                <v-card-title>
                    <span class="subtitle-1">입주 관심 상세</span>
                </v-card-title>
                <v-divider/>

                <v-card-text>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="editItem.name" label="이름" hide-details class="mt-3"></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-select v-model="editItem.interest_type" :items="interest_items" label="공급유형" hide-details class="mt-3"/>
                        </v-col>
                        <v-col class="py-0">
                            <v-select v-model="editItem.gender" :items="gender_items" label="신청자격" hide-details class="mt-3"/>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="editItem.phone" label="연락처" hide-details class="mt-3"></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="editItem.birth" label="생년월일" hide-details class="mt-3"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancel">취소</v-btn>
                    <v-btn color="primary" text @click="save(editItem)">저장</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { APPLICATION_TYPE, GENDER_TYPE, INTEREST_TYPE } from "@/assets/variables";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            shows: false,
            filter: {
                code: APPLICATION_TYPE.REGISTRATION.value,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            headers: [
                { text: "no", value: "no", align: "center", width: 50 },
                { text: "신청인", value: "name", align: "center", width: 100 },
                { text: "연락처", value: "phone", align: "center", width: 100 },
                { text: "신청자격", value: "gender", align: "center", width: 100 },
                { text: "공급유형", value: "interest_type", align: "center", width: 100 },
                { text: "생년월일", value: "birth", align: "center", width: 100 },
                { text: "신청일자", value: "createdAt", align: "center", width: 100 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],
            INTEREST_TYPE,
            GENDER_TYPE,
            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            applications: [],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "신청인", value: "name" },
                { text: "공급유형", value: "interest_type" },            
                { text: "신청자격", value: "gender" },            
                { text: "연락처", value: "phone" },
                { text: "생년월일", value: "birth" },            
            ],
            searchKey: null,
            searchValue: null,

            editItem:{
                name: "",
                phone: "",
                birth: "",
                interest_type: "",
                gender: "",
            },
            interest_items: Object.values(INTEREST_TYPE),
            gender_items: Object.values(GENDER_TYPE)
        };
    },
    computed: {
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, applications } = await api.console.application.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.applications = applications;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(item) {
            this.editItem = {...item};
            this.shows = true;
        },
        async save(item) {
            try {
                let { application } = await api.console.application.put(item);
                await this.search(false);

                alert("수정되었습니다.");
                this.shows = false;

            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        cancel() {
            this.shows = false;
            this.editItem = {};
        },
        async remove(item) {
            try {
                if (confirm("입주관심을 삭제하시겠습니까?")) {
                    await api.console.application.delete(item);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        onClickSearch() {
            this.page = 1;
            this.search();
        },
        initSearch() {
            this.filter = {
                code: null,
                searchKey: null,
                searchValue:  null,
                createdAt: [null, null],
            },
            this.search();
        },
        async excel(){
            try {
                const { applications } = await api.console.application.gets({
                    params: this.filter,
                });

                var rows = applications.map((application) => ({
                    신청일자: this.$dayjs(application?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    신청인: application?.name,
                    연락처: application?.phone,
                    생년월일: application?.birth,
                    신청자격: GENDER_TYPE[application?.gender]?.text,
                    공급유형: INTEREST_TYPE[application?.interest_type]?.text,
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, `입주관심목록_${this.$dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        }
    },
};
</script>
