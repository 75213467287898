<template>
    <v-card v-show="showSearch" class="elevation-1 mx-auto">
        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="3">
                    <v-date-field v-model="query.createdAt[0]" label="작성일자(시작)" persistent-placeholder dense hide-details />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-date-field v-model="query.createdAt[1]" label="작성일자(종료)" persistent-placeholder dense hide-details />
                </v-col>
                <v-col cols="6" md="3">
                    <v-select v-model="query.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder dense hide-details @change="() => (query.searchValue = '')" />
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field v-model="query.searchValue" label="검색어" persistent-placeholder :disabled="!query.searchKey" dense hide-details @keydown.enter="setQuery"/>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col cols="4">
                    <v-select v-model="query['qualification_type']" :items="qualification_items" label="신청자격" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
                <v-col cols="4">
                    <v-select v-model="query['supply_category']" :items="category_items" label="공급유형" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
                <v-col cols="4">
                    <v-select v-model="query['supply_type']" :items="type_items" label="공급타입" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="query['earning_rank']" :items="rank_items" label="소득순위" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="query['region_rank']" :items="rank_items" label="자역순위" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="query['isHomeless']" :items="boolean_items" label="무주택 충족 여부" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="query['isAsset']" :items="boolean_items" label="자산기준 충족 여부" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="query['isCar']" :items="boolean_items" label="자동차가액 충족 여부" persistent-placeholder dense hide-details @change="setQuery"/>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
            <v-spacer />
            <v-btn color="primary" rounded text @click="clearQuery"><v-icon small class="mt-1 mr-2">mdi-replay</v-icon>초기화</v-btn>
            <v-btn color="primary" rounded text @click="setQuery"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { QUALIFICATION_TYPE, RANKING_LIST, SUPPLY_CATEGORY, SUPPLY_TYPE } from "@/assets/variables";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

const BOOLEAN_LIST = {
    true: { text: "예", value: "true" },
    false: { text: "아니요", value: "false" },
}

let initQuery = (query = {}) => ({
    ...query,
    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,

    ["qualification_type"]: query?.["qualification_type"] || null,
    ["supply_category"]: query?.["supply_category"] || null,
    ["supply_type"]: query?.["supply_type"] || null,
    ["earning_rank"]: query?.["earning_rank"] || null,
    ["region_rank"]: query?.["region_rank"] || null,
    ["isHomeless"]: query?.["isHomeless"] != null ? query?.["isHomeless"] : null,
    ["isAsset"]: query?.["isAsset"] != null ? query?.["isAsset"] : null,
    ["isCar"]: query?.["isCar"] != null ? query?.["isCar"] : null,

    ["createdAt"]: [(query?.["createdAt"] || [])[0], (query?.["createdAt"] || [])[1]],
});
export default {
    components: {
        VDateField,
    },
    data: () => ({
        showSearch: true,
        query: initQuery(),
        searchKeys: [
            { text: ":: 전체 ::", value: null },
            { text: "신청인", value: "name" },
            { text: "연락처", value: "phone" },
            { text: "이메일", value: "email" },
            { text: "생년월일", value: "birth" },
        ],
        searchKey: null,
        searchValue: null,

        category_items: [{ text: ":: 전체 ::", value: null}, ...Object.values(SUPPLY_CATEGORY)],
        qualification_items: [{ text: ":: 전체 ::", value: null}, ...Object.values(QUALIFICATION_TYPE)],
        type_items: [{ text: ":: 전체 ::", value: null}, ...Object.values(SUPPLY_TYPE)],
        rank_items: [{ text: ":: 전체 ::", value: null}, ...Object.values(RANKING_LIST)],
        boolean_items: [{ text: ":: 전체 ::", value: null}, ...Object.values(BOOLEAN_LIST)],
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.["_user"]) delete query["_user"];

            if (!query?.["createdAt"]) delete query["createdAt"];
            if (!query?.["createdAt"]?.[0] && !query?.["createdAt"]?.[1]) delete query["createdAt"];

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];
            if (!query?.["qualification_type"]) delete query["qualification_type"];
            if (!query?.["supply_category"]) delete query?.["supply_category"];
            if (!query?.["supply_type"]) delete query?.["supply_type"];
            if (!query?.["earning_rank"]) delete query["earning_rank"];
            if (!query?.["region_rank"]) delete query["region_rank"];
            if (query?.["isHomeless"] == null) delete query["isHomeless"];
            if (query?.["isAsset"] == null) delete query["isAsset"];
            if (query?.["isCar"] == null) delete query["isCar"];

            delete query.page;

            this.$router.push({ query });
        },
        clearQuery() {
            this.query = initQuery();
            this.setQuery();
        }
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
};
</script>

<style>
</style>